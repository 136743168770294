// extracted by mini-css-extract-plugin
export var lSection__headerNoborder = "r";
export var aTitle = "s";
export var lSection__header = "t";
export var aDetail = "u";
export var aLabel = "v";
export var lSection__moreNoborder = "w";
export var aLink = "x";
export var lSection__more = "y";
export var aCategoryGray = "z";
export var aCategory = "A";
export var aDate = "B";
export var aButton1 = "C";
export var aButton2 = "D";
export var aButton3 = "E";
export var aButton4 = "F";
export var aButton5 = "G";
export var aButtonHeaderLogin = "H";
export var aButtonHeaderRegister = "I";
export var aButtonBig = "J";
export var aButtonRegister = "K";
export var aButtonLogin = "L";
export var aButtonContinuation = "M";
export var icon = "N";
export var aButtonHeaderSpLogin = "O";
export var aArrow1 = "P";
export var aArrow2 = "Q";
export var lSection = "R";
export var lSection__inner = "S";
export var aText = "T";
export var lSection__column2Border = "U";
export var lSection__column2 = "V";
export var lSection__content = "W";
export var lSection__contentNoBorder = "X";
export var aTitle1 = "Y";
export var aFullImage = "Z";
export var aButtons = "_";
export var aTags = "aa";
export var lSection__iframeBorder = "ba";
export var lSection__iframe = "ca";
export var lSection__figureBox = "da";
export var animationMarquee = "ea";